:root,
:host {
  --primary-color: #FF8324;
  --secondary-color: #1E8C45;
  --tertiary-color: #B0063E;
  --smoothr-color: #521340;
  --theme-color: #3f51b5;
  --black-color: #333333;
  --not-active-color: #b0063f91;

  --bg-color: #F9F9F9;
  --pale-gray: #EDEDED;
  --intensive-gray: #C5C5C5;
}
