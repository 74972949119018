@use '@angular/material' as mat;

@include mat.core();

$palette-primary: (
  50 : #e8eaf6,
  100 : #c5cbe9,
  200 : #9fa8da,
  300 : #7985cb,
  400 : #5c6bc0,
  500 : #3f51b5,
  600 : #394aae,
  700 : #3140a5,
  800 : #29379d,
  900 : #1b278d,
  A100 : #c6cbff,
  A200 : #939dff,
  A400 : #606eff,
  A700 : #4757ff,
  contrast: (
      50 : #fff,
      100 : #fff,
      200 : #fff,
      300 : #fff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$palette-secondary: (
    50 : #e7f5ea,
    100 : #c2e5cb,
    200 : #9ad4a9,
    300 : #71c287,
    400 : #52b56d,
    500 : #34a853,
    600 : #2fa04c,
    700 : #279742,
    800 : #218d39,
    900 : #157d29,
    A100 : #b3ffc0,
    A200 : #80ff95,
    A400 : #4dff6b,
    A700 : #33ff56,
    contrast: (
        50 : #fff,
        100 : #fff,
        200 : #fff,
        300 : #fff,
        400 : #fff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$my-app-primary: mat.define-palette($palette-primary);
$my-app-accent: mat.define-palette($palette-secondary);

$my-app-warn: mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-light-theme((
  color: (
    primary: $my-app-primary,
    accent: $my-app-accent,
    warn: $my-app-warn,
  )
));

@include mat.all-component-themes($my-app-theme);



.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}
.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}
.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}
.cdk-drag-preview .mat-ripple-element,
.cdk-drag-placeholder .mat-ripple-element {
  display: none;
}
.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir="rtl"] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.mat-mdc-button-touch-target {
  height: 100% !important;
}

mat-slide-toggle {
  --mdc-switch-unselected-handle-color: var(--tertiary-color);
  --mdc-switch-unselected-icon-color: white;
}

.mdc-switch__handle::after {
  --mdc-switch-unselected-handle-color: var(--tertiary-color) !important;
}

.mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__handle::after {
  --mdc-switch-unselected-hover-handle-color: var(--tertiary-color) !important;
  --mdc-switch-unselected-pressed-handle-color: var(--tertiary-color) !important;
}
.mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  --mdc-switch-unselected-hover-handle-color: var(--tertiary-color);
}
.mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after {
  --mdc-switch-unselected-focus-handle-color: var(--tertiary-color);
}

mat-error {
  font-size: 13px;
}

