/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use './theme/m3-theme.scss';
@use './theme/variables.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";

@import url('./theme/toaster.css');

// :root {
//   @include mat.all-component-themes(m3-theme.$light-theme);
// }

@font-face {
	font-family: AppFont;
	src: url(./assets/fonts/FlameSans.otf);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html,
body {
	height: 100%;
	font-size: 16px;
	margin: 0;
	height: 100vh;
	width: 100vw;
	overflow: auto; /* Prevent scrolling */
}


body {
	margin: 0;
	font-family: AppFont, 'Helvetica Neue', sans-serif;
	color: var(--black-color);
	font-weight: 400;
	letter-spacing: 0.02em;

	--mat-toolbar-container-text-color: var(--black-color);
}

/**************** Scroll Style ******************/

*::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

*::-webkit-scrollbar-track {
	background-color: #f9f9f9;
	border-radius: 100px;
}

*::-webkit-scrollbar-thumb {
	background-color: #c3c3c1;
	border-radius: 100px;
}


.success-snackbar {
	background-color: #4caf50; /* Green */
	color: white;
}

.error-snackbar {
	background-color: #f44336; /* Red */
	color: white;
}

.warning-snackbar {
	background-color: #ff9800; /* Orange */
	color: white;
}

.info-snackbar {
	background-color: #2196f3; /* Blue */
	color: white;
}
